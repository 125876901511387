.blink {
  animation: blink 2s ease-in-out infinite;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}