@tailwind components;
@tailwind utilities;

@import "react-data-grid/lib/styles.css";

@import './animations.scss';

* {
  box-sizing: border-box;
}

main {
  @apply relative px-0 z-0;
  @apply sm:px-8;
  @apply lg:px-16;
}

a {
  @apply no-underline;
}

ul {
  @apply pl-3;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: #FFFFFF;
    background: #000000;
  }
}

.bn-container[data-color-scheme] {
  --mantine-font-family: inherit;
  --bn-font-family: var(--mantine-font-family);


  --mantine-font-family-headings: inherit;
  --bn-font-family-headings: var(--mantine-font-family-headings);
}

.bn-container[data-tsa-report] {
  .bn-editor {
    * {
      font-family: var(--mantine-font-family);

      .bn-default-styles {
        font-family: var(--mantine-font-family);
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: var(--mantine-font-family-headings);
      font-weight: 600;

      * {
        font-family: var(--mantine-font-family-headings);
      }
    }
  }
}

/* Print */
@media print {
  header, footer, [data-tsa-noprint] {
    display: none !important;
  }

  main {
    padding: 0 !important;
  }

  [data-tsa-report],
  [data-tsa-print-container] {
    margin: 0 !important;
  }

  [data-tsa-print-container] {
    width: 100% !important;
    max-width: none !important;
  }

  @page {
    margin: 1cm 0.5cm;
  }
}
