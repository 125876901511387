.footer {
  margin-top: 6rem;
  border-top: 1px solid var(--mantine-color-gray-2);
}

.inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}