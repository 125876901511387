$tsa-local-login-height: 100vh;

.root {
  height: $tsa-local-login-height;
}

.sidebar {
  @apply w-full sm:w-[450px];
  border-right: 1px solid var(--mantine-color-gray-3);
  height: $tsa-local-login-height;
  padding-top: 80px;
}

.logo {
  @apply block relative;
  width: 9rem;
  height: 3.6rem;
  margin: 1rem auto 3rem;
}

.imageWrapper {
  @apply flex-1 hidden sm:flex;
}