.root {
  position: relative;
  z-index: 1;
}

.dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: 0;
  overflow: hidden;
  background-color: var(--mantine-color-white);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

%link {
  @apply py-2 px-3;
  display: block;
  line-height: 1;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  font-family: var(--mantine-font-family-headings);
  font-weight: 500;

  @media (max-width: 720px) {
    @apply py-8 px-3;
    font-size: var(--mantine-font-size-md);
  }
}

.link {
  @extend %link;
  color: var(--mantine-color-gray-7);

  &:hover {
    background-color: var(--mantine-color-gray-0);
  }
}

.linkDisabled {
  @extend %link;
  color: var(--mantine-color-gray-5);

  cursor: default;
}

.logo {
  position: relative;
  width: 5.5rem;
  height: 100%;
}

.user {
  color: var(--mantine-color-black);
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
}